import React from "react";

const MainContents = ({ leftColumnWidth }) => {
  return (
    <main className="px-4 py-2" style={{ marginLeft: leftColumnWidth }}>
      <h1>AWS Security Governance at Scale へようこそ！（<a href="https://aws.amazon.com/jp/training/classroom/aws-security-governance-at-scale/" target="blank">AWS Security Governance at Scale について</a>）</h1>
      <hr class="koji"/>
      <h3 id="prepare" class="anchor">初日の準備</h3>
      <div class="box">
      <h5>受講準備</h5>
        <p>
        ===== 受講環境について =====<br/>
        ・ブラウザは Chrome もしくは Firefox をお使いください。<br/>
        ・極力 VPN は切断してください。（通信が不安定になる可能性があります）<br/>
        ・本コースではマイクの使用は必須ではありません。スピーカー（やヘッドホンなど）を準備いただければ OK です。<br/>
        ・可能であればモニターは複数用意していただくと演習（ラボ）を効率的に進めることができます。（手順書と操作画面を行ったり来たりする為）<br/>
        ===== <a href="https://a.co/7SeNx7p" target="blank">【1】配信ツール（Webex）の使い方</a> =====<br/>
        ・音声が聞こえない方は P. 27 をご確認ください<br/>
        ===== <a href="https://awsj-tc-training-public.s3-ap-northeast-1.amazonaws.com/vILT/common/GTT_eVantage_Bookshelf_ver2.pdf" target="blank">【2】受講者ガイド利用手順</a> =====<br/>
        ・コース開始前に <a href="https://evantage.gilmoreglobal.com/" target="blank">こちら</a> からアカウントの作成をお願いします。<br/>
        ・講義で用いる教材は「Student Guide」、演習の手順書は「Lab Guide」となります。<br/>
        ・ただし、スライドの並び替えや取捨選択、補足資料の追加などをしておりますので、教材ではなく Webex の画面を見ながらの受講をお願いします。<br/>
        ・トレーニングの期間中に必ず 1 度は教材への閲覧をお願いします。<br/>
        ・教材は、引き換え後、720 日間閲覧可能です。<br/>
        ※ 稀に、引き換え後にガイドが表示されないことがあります。同期の問題であることが多いので、午後に [更新] ボタンを押してみてください。<br/>
        ====== 【3】受講者ポータルへのアクセス方法 =====<br/>
        ・ログインページは <a href="" target="blank">こちら(2023xxxx - 2023xxxx)</a> です。<br/>
        ・受講者ポータルから、ラボ（ハンズオン）の実施や、教科書の閲覧ができます。<br/>
        ・研修を申し込む際に使用したアカウントでログインをお願いします。<br/>
        ・受講者ポータルでのラボ実施は、トレーニング中の3日間のみアクセス可能です。ご留意下さいませ。<br/>
        </p>
        <hr/>
        <h5>付加資料に関して</h5>
        <p>
          本トレーニング内で適宜まとめております資料につきましては別途 PDF にて切り出しております。 (転送等はお控え下さいますよう、お願い致します。)<br/>
          <a href="#" target="blank">トレーニング付加資料 (PDF)</a><br />
        </p>
      </div>
      <hr class="koji"/>
      <h3 id="agenda" class="anchor">アジェンダ</h3>
      <div class="box">
        <div class="blackboard-box">
          <p><b><span class="warn">下記はあくまで予定なので参考程度にお考えください</span></b></p>
          <p>みなさんが手を動かす時間を多めに取る予定です</p>
          <div class="chalk1"></div>
          <div class="chalk2"></div>
        </div>
        <h5>Day 1</h5>
        <table>
          <tr>
            <th class="a">モジュール</th>
            <th class="b">タイトル</th>
            <th class="c">ラボ</th>
          </tr>
          <tr>
            <td>1</td>
            <td>大規模環境におけるガバナンス</td>
            <td></td>
          </tr>
          <tr>
            <td>2</td>
            <td>ガバナンスのオートメーション</td>
            <td></td>
          </tr>
          <tr>
            <td>3</td>
            <td>予防制御</td>
            <td><span class="point_box"><b>ラボ1</b></span>AWS Service Catalog からクラウドリソースをデプロイする</td>
          </tr>
          <tr>
            <td>4</td>
            <td>検出制御</td>
            <td><span class="point_box"><b>ラボ2</b></span>AWS Systems Manager でアクションを起こす<br /><span class="point_box"><b>ラボ3</b></span>AWS Config でのコンプライアンスとセキュリティのオートメーション</td>
          </tr>
        </table>
      </div>
      <hr class="koji"/>
      <h3 id="modules" class="anchor">参考リンク</h3>
      <div class="box">
        <h5>モジュール 1：大規模環境におけるガバナンス</h5>
        ・<a href="https://docs.aws.amazon.com/ja_jp/organizations/latest/userguide/orgs_manage_policies_scps.html" target="blank">サービスコントロールポリシー (SCP)</a><br/>
        ・<a href="https://dev.classmethod.jp/articles/organizations-scp-inheritance/" target="blank">[AWS Organizations] SCP(サービスコントロールポリシー)の継承の仕組みを学ぼう＜外部サイト＞</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/config/latest/developerguide/conformance-pack-console.html" target="blank">AWS Config コンソールを使用したコンフォーマンスパックのデプロイ</a><br/>
        <hr/>
        <h5>モジュール 2：ガバナンスのオートメーション</h5>
        ・<a href="https://docs.aws.amazon.com/whitepapers/latest/organizing-your-aws-environment/organizing-your-aws-environment.html" target="blank">Organizing Your AWS Environment Using Multiple Accounts</a><br/>
        ・<a href="https://engineering.visional.inc/blog/171/awssummit_securityguardrail/" target="blank">100を超えるAWSアカウント運用におけるガードレール構築事例＜外部サイト＞</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/CUS-06_AWS_Summit_Online_2020_Visional%20Inc.pdf" target="blank">大規模な組織変遷と100以上のAWSアカウントの横断的セキュリティガードレール運用について</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/controltower/latest/userguide/getting-started-with-control-tower.html" target="blank">AWS Control Tower の使用開始方法</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/controltower/latest/userguide/terminology.html" target="blank">用語（AWS Control Tower 関連）</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/startup/multi-accounts-and-control-tower/" target="blank">スタートアップにおけるマルチアカウントの考え方と AWS Control Tower のすゝめ</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/controltower/latest/userguide/sso.html" target="blank">AWS IAM Identity Center (successor to AWS Single Sign-On) によるユーザーとアクセスの管理</a><br/>
        ・<a href="https://controltower.aws-management.tools/aa/" target="blank">Authentication & Authorization</a><br/>
        <hr/>
        <h5>モジュール 3：予防制御</h5>
        ・<a href="https://docs.aws.amazon.com/ja_jp/servicecatalog/latest/adminguide/introduction.html" target="blank">Service Catalog とは</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/servicecatalog/latest/adminguide/catalogs_budgets.html" target="blank">予算の管理</a><br/>
        <hr/>
        <h5>モジュール 4：検出制御</h5>
        test
        <hr/>
      </div>
      <hr class="koji"/>
      <h3 id="survey" class="anchor">アンケート</h3>
      <div class="box">
        <h5>アンケートの入力</h5>
        <ol>
          <li><a href="https://www.aws.training">https://www.aws.training</a> へアクセス</li>
          <li>「サインイン」※一般、もしくはAPNパートナー　でサインイン ボタンが異なります</li>
          <li>アカウント > トレーニングデータ　をクリック</li>
          <li><b>アーカイブ済み</b> タブ　で受講したトレーニングを表示</li>
          <li><b>評価</b>　をクリック</li>
          <li>アンケートとコメントを入力</li>
        </ol>
        <img src="survey.png" />
      </div>
      <hr class="koji"/>
      <h3 id="faq" class="anchor">FAQ</h3>
      <div class="box">
        <h5>トレーニングテキスト</h5>
        Q. トレーニングテキストはいつまで参照できますか？<br/>
        &nbsp;&nbsp;・ダウンロードから720日間参照できます。<br/><br/>
        Q. トレーニングテキストは印刷できますか？<br/>
        &nbsp;&nbsp;・Bookshelf というアプリケーションを利用することで印刷が可能です。ただし、一度に印刷できるのは 50 ページの制限があります。無断配布は禁じられております。<br/>
        &nbsp;&nbsp;・<a href="http://awsj-tc-training-public.s3-ap-northeast-1.amazonaws.com/vILT/common/GTT_eVantage_Bookshelf_ver2.pdf" target="blank">Bookshelfの入手方法はこちら (PDF)</a>
        <hr/>
        <h5>Rocket Chat</h5>
        Q. チャットの内容はいつでも参照できますか？<br/>
        &nbsp;&nbsp;・いいえ、トレーニング最終日の翌日または翌々日を目処に削除します。
      </div>
    </main>
  );
};

export default MainContents;
